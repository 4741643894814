import React, {useEffect, useRef} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper, Subtitle, Title } from "./infoElements";
import { ButtonBasicInv } from "../ButtonElements";
import { motion, useInView,useAnimation } from "framer-motion"


export const InfoSectionLight = ({id,title,subtitle,text,image,btnText}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true});
  const mainControls = useAnimation();

  useEffect(() => {
    if(isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView])

  return (
    <>
      <InfoContainer id={id} className="bg-white">
        <InfoWrapper>
          <motion.div ref={ref} className="row expand-row gx-5"
          variants={{hidden: {opacity: 0, x:-100},visible: {opacity: 1, x:0}}}
          initial = "hidden"
          animate = {mainControls}
          transition={{duration:0.5, delay:0.25}}
          >
            <div className="col-lg-6 col-sm-12 img-wrap">
              <img src="https://www.hdfclife.com/content/dam/hdfclifeinsurancecompany/knowledge-center/images/tax/How-to-File-Income-Tax-Return.png" alt="" className="fit-img"></img>
            </div>
            <div className="col-lg-6 col-sm-12 my-auto">
              <Subtitle>{subtitle}</Subtitle>
              <Title className="mb-5">{title}</Title>
              <p className="text-black mb-4">{text}</p>
              <ButtonBasicInv to="" primary="false" dark="true" className="mx-auto">
                {btnText}
              </ButtonBasicInv>
            </div>
          </motion.div>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSectionLight;

