import React from 'react';
import styles from './ExpertList.module.css';
import ExpertCard from '../ExpertCard/ExpertCard'

const ExpertList = ({ experts, onHireExpert, allocatedExpertId }) => {

  return (
    <div className={styles['expert-list']}>
      {experts.data.map((expert, index) => (
        <ExpertCard 
          key={index} 
          expert={expert} 
          onHireClick={onHireExpert}
        />
      ))}
    </div>
  );
};

export default ExpertList;