import React, { useState } from 'react';
import styles from './SearchBar.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SearchBar = ({ onSearch }) => {
  const [location, setLocation] = useState('');

  const handleSearch = () => {
    if (location === '') {
      toast.warning('Enter the location');
      return;
    }
    onSearch(location);
  };

  return (
    <div className={styles['search-bar']}>
      <input
        className={styles['search-input']}
        type="text"
        placeholder="Enter location..."
        value={location}
        onChange={(e) => setLocation(e.target.value)}
      />
      <button className={styles['search-btn']} onClick={handleSearch}>Search</button>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default SearchBar;
