import React, { useState } from 'react'
import styles from './AboutUs.module.css';
import { Sidebar } from '../../components/sidebar';
import { Navbar } from '../../components/navbar';
import HeroSection from '../../components/heroSection';
import Footer from '../../components/footer';

const AboutUs = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <div className={styles.aboutUsWrapper}>
        <div className={styles.aboutUsContainer}>
          <h1>Dataknobs Commitments | How We Operate</h1>
          <p>
            Dataknobs focuses on Data Centric AI. Using knobs (abstract levers), we make data usable and useful in a governed manner. Using knobs, we create data, augment data, anonymize data, and make it usable. To make data useful, we enable experimentation with knobs. Using knobs, one can also publish/share insights to different channels.
          </p>

          <h3>About Dataknobs</h3>
          <h6>Innovate with Responsibility</h6>
          <p>
            Instead of focusing on sophisticated algorithms, we focus on building quality dataset(s) that have high predictive power. "We help you power your AI applications with the data you need."
          </p>

          <h6>Generative AI</h6>
          <p>
            We use generative AI in a controlled manner. We have built methods to govern/control/validate generative AI output. We are making more progress in this area.
          </p>

          <h3>Our Products</h3>
          <ul>
            <li><strong>KREATE:</strong> Use knobs to generate web experience. Behind the scene automation + AI + Generative AI is used.</li>
            <li><strong>KONTROLS:</strong> Use knobs to define controls for AI and generative AI.</li>
            <li><strong>AB Experiment:</strong> Using knobs allows teams to experiment quicker and cheaper.</li>
          </ul>

          <h3>Our Commitments</h3>
          <p>
            At our company, we are committed to advancing the field of artificial intelligence and leveraging its potential to create a better world. Our commitments are as follows:
          </p>
          <ul>
            <li><strong>Ethical AI:</strong> We are committed to building ethical AI systems that prioritize human values and rights. Our AI systems will be designed with transparency, fairness, and accountability in mind, and we will work to mitigate any potential biases and risks associated with their use.</li>
            <li><strong>Innovation:</strong> We are committed to driving innovation in the field of AI and developing cutting-edge technologies that can solve real-world problems. We will invest in research and development, collaborate with industry and academic partners, and continuously improve our AI systems to deliver the best possible results.</li>
            <li><strong>Customer Success:</strong> We are committed to the success of our customers and will work closely with them to understand their unique needs and goals. We will provide them with the best possible solutions and support to help them achieve their objectives.</li>
            <li><strong>Privacy and Security:</strong> We are committed to protecting the privacy and security of our customers' data and ensuring that our AI systems comply with all relevant regulations and standards. We will implement robust security measures and data protection protocols to safeguard our customers' data.</li>
            <li><strong>Diversity and Inclusion:</strong> We are committed to promoting diversity and inclusion in the field of AI and building a team that reflects the diversity of our global community. We will create an inclusive workplace culture that fosters creativity, collaboration, and innovation.</li>
          </ul>

          <p>
            By upholding these commitments, we aim to be a trusted partner for our customers, a leading innovator in the field of AI, and a responsible member of the global community.
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutUs
