import React, {useState} from 'react'
import './GoogleSearch.css'
import { useNavigate } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';


const GoogleSearch = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        if(searchTerm === '')
            return;
        
        navigate(`/search/${searchTerm}`);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            navigate(`/search/${searchTerm}`);
        } else {
            return;
        }
    }

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className='srch-bar'>
            <input
                className='srch-input'
                type="text"
                placeholder="Google Search..."
                value={searchTerm}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
            />
            <button className='srch-btn' onClick={handleSearch}>Search</button>
            <FaSearch className='srch-btn1' onClick={handleSearch}/>
        </div>
    );
}

export default GoogleSearch
