import styled, { css } from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";


const media = {
  mobile: (...args) => css`
    @media (max-width: 1000px) {
      ${css(...args)}
    }
  `,

  mobilesm: (...args) => css`
    @media (max-width: 654px) {
      ${css(...args)}
    }
  `,
};

export const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;
  z-index: 1;
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(https://storage.googleapis.com/layout.kreatewebsites.com/atlas/img/banner-bk.jpg);
`;


export const HeroContent = styled.div`
  z-index: 3;
  ${'' /* max-width: 1200px; */}
  width: 100vw
  position: absolute;
  ${'' /* padding: 8px 24px; */}
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.mobile`
    h1 {
      font-size: 40px;
    }

    p {
      font-size: 15px;
    }
  `}

  ${media.mobilesm`
    h1 {
      font-size: 5vw;
    }

    p {
      font-size: 13px;
    }
  `}
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
