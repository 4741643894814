import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from './SignUpOptions.module.css';
import { useUser } from "../../UserContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";

const SignUpOptions = () => {
  const { userType, setUserType } = useUser();
  const navigate = useNavigate();

  const handleRadioChange = (e) => {
    setUserType(e.target.value);
  };

  const handleContinue = () => {
    if(userType === null) {
        toast.error("select a role")
        return;
    }
    navigate(`/signup`);
  };

  return (
    <div className={styles['outer-container']}>
      <Helmet>
        <title>Join NRI Tax - Create Your Account</title>
        <meta 
          name="description" 
          content="Start your NRI tax journey today. Sign up for expert assistance, personalized advice, and simplified tax solutions tailored to your needs." 
        />
      </Helmet>
        <div className={styles['nav']}>
          <h1><Link style={{ color: 'white', textDecoration: 'none' }} to='/'>NriTax</Link></h1>
        </div>
        <div className={styles['inner-container']}>
            <h1>Sign Up as an Expert or User</h1>
            <div className={styles['grid']}>
                <label className={`${styles['container']} ${userType === 'user' ? styles['selected'] : ''}`} htmlFor="user">
                    <p>Sign Up as User</p>
                    <input
                    className={styles['input']}
                    type="radio"
                    id="user"
                    name="role"
                    value="user"
                    onChange={handleRadioChange}
                    checked={userType === 'user'}
                    />
                </label>
                <label className={`${styles['container']} ${userType === 'expert' ? styles['selected'] : ''}`} htmlFor="expert">
                    <p>Sign Up as Expert</p>
                    <input
                    className={styles['input']}
                    type="radio"
                    id="expert"
                    name="role"
                    value="expert"
                    onChange={handleRadioChange}
                    checked={userType === 'expert'}
                    />
                </label>
            </div>
            <button className={styles['continue-btn']} onClick={handleContinue}>Continue</button>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
    </div>
  );
};

export default SignUpOptions;
