import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Helmet } from "react-helmet";
import './FileTips.css';
import { Sidebar } from '../../components/sidebar';
import { Navbar } from '../../components/navbar';

const FileTips = () => {
    const [data, setData] = useState(null);
    const [showDescription, setShowDescription] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        fetch("/file-tips.json")
            .then(response => response.json())
            .then(sdata => {
                setData(sdata);
                setShowDescription(new Array(sdata.length).fill(false));
            })
            .catch(error => {
                setData([]);
                console.error(error)
            });
    }, []);

    const toggleDescription = (index) => {
        const newShowDescription = [...showDescription];
        newShowDescription[index] = !newShowDescription[index];
        setShowDescription(newShowDescription);
        setSelectedIndex(index);
    };

    return (
        <div className='outer-container'>
            <Helmet>
                <title>NRI Tax - Filing Tips </title>
                <meta
                    name="description"
                    content="Explore invaluable NRI tax filing insights and comprehensive guidance tailored for non-resident Indians. Acquire expert advice on optimizing the tax filing process as an NRI, ensuring efficiency, and strategically maximizing your returns. Uncover essential tips and recommendations to navigate the complexities of tax obligations, enabling you to make informed decisions and enhance your financial outcomes."
                />
                <meta
                    name="keywords"
                    content="filing, tips, advice, taxes, NRI, Tax planning, Investment income, Tax deductions"
                />
            </Helmet>

            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} backgroundColor="#192440"/>

            <div className="content">
                {data === null ? (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                        <PulseLoader style={{ alignContent: 'center' }} color={'#36D7B7'} size={13} />
                    </div>
                ) : data.length === 0 ? (
                    <p className='center-text'>No data Available</p>
                ) : (
                    data.map((item, index) => (
                        <div key={index} className="article-card">
                            <h4 className='item-title'>{item.title}</h4>
                            {showDescription[index] ? (
                                item.content.split('\n').map((line, index) => (
                                    <p className='item-description' key={index}>{line}</p>
                                ))
                            ) : (
                                <p>{item.content.substring(0, 200)}...</p>
                            )}

                            <button className='read-more-button' onClick={() => toggleDescription(index)}>
                                {showDescription[index] ? "Read Less" : "Read More"}
                            </button>
                        </div>
                    ))
                )
                }
            </div>
        </div>
    );
};

export default FileTips;
