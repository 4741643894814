import React, { createContext, useContext, useState } from 'react';

const ExpertDetailsContext = createContext();

export const ExpertDetailsProvider = ({ children }) => {
  const [personalDetails, setPersonalDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const [educationDetails, setEducationDetails] = useState({
    degree: "",
    university: "",
    fieldOfStudy: "",
    graduationYear: "",
  });

  const [experienceDetails, setExperienceDetails] = useState({
    description: "",
    linkedin: "",
  });

  return (
    <ExpertDetailsContext.Provider
      value={{
        personalDetails,
        setPersonalDetails,
        educationDetails,
        setEducationDetails,
        experienceDetails,
        setExperienceDetails,
      }}
    >
      {children}
    </ExpertDetailsContext.Provider>
  );
};

export const useExpertDetails = () => {
  return useContext(ExpertDetailsContext);
};



