import React,  {useEffect, useRef} from 'react';
import styles from './ExpertCard.module.css'; 
import { motion, useInView,useAnimation } from "framer-motion"

const ExpertCard = ({ expert, onHireClick }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true});
  const mainControls = useAnimation();

  useEffect(() => {
    if(isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView])

  const handleHireClick = () => {
    // Call the onHireClick function with the expert's ID when the "Hire" button is clicked
    onHireClick(expert._id);
  };

  return (
    <motion.div className={styles['expert-card']}
    ref={ref}
    variants={{hidden: {opacity: 0, y:-100},visible: {opacity: 1, y:0}}}
    initial = "hidden"
    animate = {mainControls}
    transition={{duration:0.5, delay:0.25}}
    >
      <p>Name: {expert.personalDetails.firstName} {expert.personalDetails.lastName}</p>
      <p>Location: {expert.personalDetails.state}</p>
      <p>Email: {expert.personalDetails.email}</p>
      <p>Phone No: {expert.personalDetails.phoneNumber}</p>
      <button className={styles['hire-btn']} onClick={handleHireClick}>Hire</button>
    </motion.div>
  );
};

export default ExpertCard;
