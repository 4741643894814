import React, { useState } from "react";
import HeroSection from "../components/heroSection";
import InfoSection from "../components/infoSection";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";
import Image1 from "../images/design-notes.svg";
import Image2 from "../images/space.svg";
import InfoSectionLight from "../components/infoSectionLight";
import Services from "../components/services";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Helmet>
        <title>NRI Tax Solutions - Expert Guidance for Non-Resident Indian Taxation</title>
        <meta
          name="description"
          content="Empower NRI tax solutions with expert guidance - Connect, consult, and optimize your tax matters seamlessly on our platform."
        />
        <meta
          name="keywords"
          content="NRI, Taxation, Expert, Consultation, Solutions, Overseas, Financial, Planning, International, Guidance"
        />
      </Helmet>

      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <HeroSection />

      <InfoSection
        image={Image1}
        id="about"
        subtitle="Global Tax Compliance Made Easy: Your Guide to NRI Taxation"
        title="Easy Tax Filling"
        text="We offer a comprehensive platform tailored to the needs of Non-Resident Indians (NRIs), simplifying the complexities of tax compliance. Its user-friendly interface provides easy navigation through various tax regulations, guidelines, and forms relevant to NRIs. The website features a secure document upload and storage system, ensuring the confidentiality of sensitive financial information. Real-time currency conversion tools enable seamless calculations and reporting in multiple currencies, enhancing accuracy in tax filings. Additionally, the website offers a dedicated customer support section, providing expert assistance and clarifications on tax queries specific to NRIs, ultimately making the tax-filing process efficient and hassle-free for the NRI community."
        btnText="Start today"
      />
      <InfoSectionLight
        image={Image2}
        id="discover"
        subtitle="Whether it be you or Us"
        title="Taxes made Easy"
        text="We  are committed to simplifying your tax journey. Whether you're an individual, a business owner, or an NRI, we provide comprehensive assistance to ensure a hassle-free tax filing experience. Our dedicated team of experts is here to guide you through the intricacies of tax regulations, helping you maximize deductions and minimize liabilities. With our services, you can rest assured that your taxes are filed accurately and on time. Additionally, if you require personalized financial guidance, we can connect you with a qualified Chartered Accountant (CA) to address your specific needs. Trust us to be your reliable partner in navigating the complex world of taxation and financial management."
        btnText="Explore"
      />
      <Services />
      <InfoSectionLight
        image={Image1}
        id="#"
        subtitle="Your Partner in Financial Ease"
        title="Streamline Tax Filing"
        text="At Taxes Made Easy, we're here to make your tax experience stress-free. Whether you're an individual, a business owner, or an NRI, we offer comprehensive support to streamline your tax filing process. Our expert team is dedicated to helping you navigate tax regulations, ensuring you get maximum deductions and minimize liabilities"
        btnText="Read more"
      />
      <Footer />
    </>
  );
};

export default Home;