import React, { useState, useEffect } from 'react';
import ExpertList from '../../components/ExpertList/ExpertList';
import SearchBar from '../../components/SearchBar/SearchBar';
import styles from './Experts.module.css';
import { PulseLoader } from 'react-spinners';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navbar } from '../../components/navbar';
import  {useAuthState}  from "../../UserAuth";
import { Sidebar } from '../../components/sidebar';
import {Helmet} from "react-helmet";

const Experts = () => {
  const [experts, setExperts] = useState({ data: [''] });
  const [searchedExperts, setSearchedExperts] = useState({ data: [] });
  const [allocatedExpertId, setAllocatedExpertId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const user = useAuthState();

  useEffect(() => {
    fetchAllExperts();
  }, [allocatedExpertId]);

  const fetchAllExperts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tax-expert/all-tax-experts`);
      const data = await response.json();
      setExperts(data);
    } catch (error) { 
      console.error('Error fetching experts:', error);
    }
  };

  const handleSearch = async (location) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tax-expert/search?location=${location}`);
      const data = await response.json();
      setSearchedExperts(data);
    } catch (error) {
      console.error('Error searching experts:', error);
    }
  };

  const handleHireExpert = async (expertId) => {
    if(!user) {
      toast.error("Sign Up to hire the expert.");
      return;
    }
    try {
      // Check if an expert is already allocated
      if (allocatedExpertId) {
        toast.info("You have already hired an expert.");
        return;
      }
      // Perform the hiring action here by sending a request to your server
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/tax-expert/hire`, {
        "expertId": expertId,
        "userId": localStorage.getItem('userId')
        // "userId": "650b445110b9ef184e761ec7"
      });
      const data = response.data;
      if (data.success) {
        setAllocatedExpertId(expertId); // Update the allocatedExpertId in the state
        toast.success("Expert hired successfully.");
      } else {
        alert('Error hiring expert:', data.message);
      }
    } catch (error) {
      alert('Error hiring expert:', error.message);
    }
  };

  return (
    <div className={styles['outer']}>
      <Helmet>
        <title>Find Your Tax Expert - Search and Connect</title>
        <meta 
          name="description" 
          content="Discover a diverse pool of tax experts, filter by location, and hire the perfect advisor with a single click. Get personalized tax solutions tailored to your needs." 
        />
        <meta 
          name="keywords" 
          content="Experts, Search, Hire, Consultants, Advisors, Expert search, Find experts, Hire an expert" 
        />
      </Helmet>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} backgroundColor="#192440"/>
      <div className={styles['expert']}>
        <h2>Expert Details</h2>
        <SearchBar onSearch={handleSearch} />
        {
          experts.data[0] === '' ?
          <PulseLoader className={styles['loader']} color={'#36D7B7'} size={15}  /> :
          experts.data.length === 0 ?
          <p className={styles['no-expert']}>No Expert Available</p> :
          <ExpertList 
            experts={searchedExperts.data.length > 0 ? searchedExperts : experts}
            onHireExpert={handleHireExpert} // Pass the hiring function to ExpertList
            allocatedExpertId={allocatedExpertId}
          />
        }

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        </div>
    </div>
  );
};

export default Experts;