import styled from "styled-components";
import { FaTimes, FaSearch } from "react-icons/fa";
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: white;
    top: 0;
    left: 0;
    transition: 0.3s all ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    display: flex;
    flex-direction: column;
`

export const NavCont = styled.div`
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #192440;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    
`

export const NavLeft = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    height: 65px;
    padding-left: 10px;

    h1 {
        color: white;
    }
`

export const CloseIcon = styled(FaTimes)`
    font-size: 1.8rem;
    cursor: pointer;
    margin-right: 10px;
    color: #00FFAD;
`

export const SearchIcon = styled(FaSearch)`
    font-size: 1.6rem;
    cursor: pointer;
    margin-right: 10px;
    color: #00FFAD;
`

export const SidebarSearchRoute = styled(LinkR)`
    text-decoration: none;
    color: black;
    &:hover {
        color: #00FFAD;
        transition: 0.2s all ease-in-out;
    }
`

export const SidebarMenu = styled.ul`
    padding: 0;
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 60px);

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }
`

export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s all ease-in-out;
    color: black;
    padding-left: 20px;
    cursor: pointer;

    &:hover {
        color: #00FFAD;
        transition: 0.2s all ease-in-out;
    }
`

export const FootCont = styled.div`
    width: 100%;
    height: 13vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
`

export const SidebarRoute = styled(LinkR)`
    border-radius: 50px;
    text-decoration: none;
    background: #192440;
    white-space: nowrap;
    padding: 9px 20px;
    width: 20rem;
    color: white;
    font-size: 1.1rem;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    font-weight: 900;

    &:hover {
        transition: all 0.2s ease-in-out;
    }
`
