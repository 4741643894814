import React, { useState, useEffect, useRef } from 'react'
import './SearchResult.css'
import { useParams, useNavigate } from 'react-router-dom';
import searchData from '../../GoogleSearchApi.js'
import { PulseLoader } from 'react-spinners';
import GoogleSearch from '../GoogleSearch/GoogleSearch';

const SearchResult = () => {
  const { searchTerm } = useParams();
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await searchData(searchTerm);

        if (result.error && result.error.code === 429) {
          navigate('/')
        }

        setData(result);
      } catch (error) {
        setData([]);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [searchTerm]);

  // console.log(data);

  return (
    <div className='out-container'>

      <div className="nav">
        <h1>Nri Tax</h1>
        <div className='google-search-web'>
          <GoogleSearch />
        </div>
      </div>

      {data === null ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
          <PulseLoader style={{ alignContent: 'center' }} color={'#36D7B7'} size={13} />
        </div>
      ) : data.length === 0 ? (
        <p>No data Available</p>
      ) : (
        <ul className='out-list'>
          <div className='google-search-mobile'>
            <GoogleSearch />
          </div>
          {data.items.map((result, index) => (
            <li key={index} className='in-list'>

              <a className='display-link' target="_blank">
                {result.displayLink}
                <span style={{ color: "black", fontSize: '10px', fontWeight: 'bold' }}>&#709;</span>
              </a>
              <a className='title' href={result.formattedUrl} target="_blank">
                {result.title}
              </a>
              <div className="inner">
                <p className='snippet'>{result.snippet}</p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default SearchResult

