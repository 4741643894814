import React, {useState} from 'react'
import styles from './ServicesPage.module.css';
import { Sidebar } from '../../components/sidebar';
import { Navbar } from '../../components/navbar';
import HeroSection from '../../components/heroSection';
import Footer from '../../components/footer';
import Services from '../../components/services';

const ServicesPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <Services />
      <Footer />
    </>
  )
}

export default ServicesPage
