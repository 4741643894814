import React, {useEffect, useRef} from 'react';
import styles from './CustomerCard.module.css';
import { motion, useInView,useAnimation } from "framer-motion" 

const CustomerCard = ({ customer }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {once: true});
  const mainControls = useAnimation();

  useEffect(() => {
    if(isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView])
  return (
    <motion.div className={styles['customer-card']}
    ref={ref}
    variants={{hidden: {opacity: 0, y:-100},visible: {opacity: 1, y:0}}}
    initial = "hidden"
    animate = {mainControls}
    transition={{duration:0.5, delay:0.25}}
    >
      <p>Name: {customer.name}</p>
      <p>Email: {customer.email}</p>
    </motion.div>
  );
};

export default CustomerCard;