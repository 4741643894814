import React from 'react';
import styles from './CustomerList.module.css';
import CustomerCard from '../CustomerCard/CustomerCard';

const CustomerList = ({customers}) => {
  return (
    <div className={styles['customer-list']}>
      {customers.map((customer, index) => (
        <CustomerCard key={index} customer = {customer}/>
      ))}
    </div>
  );
};

export default CustomerList;