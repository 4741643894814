import React from 'react'
import { SidebarContainer, CloseIcon, SidebarRoute, SidebarMenu, SidebarLink, NavCont, NavLeft, SearchIcon, SidebarSearchRoute, FootCont } from './SidebarElements.js'
import { useAuthState } from "../../UserAuth.js";
import { useNavigate } from 'react-router-dom';
import { auth } from "../../firebase"
import { useUser } from "../../UserContext";;


export const Sidebar = ({ isOpen, toggle }) => {
    const user = useAuthState();
    const navigate = useNavigate();
    const { userType, setUserType } = useUser();

    const handleSignOut = async (e) => {
        setUserType(null);
        // After successfully setting userType, store it in local storage
        localStorage.setItem('userType', null);
        await auth
            .signOut()
            .then(() => {
                navigate("/");
            })
            .catch((error) => {
                console.log("Sign out error:", error);
            });
        localStorage.removeItem('userId');
        localStorage.removeItem('expertId');
    };

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <NavCont>
                <NavLeft>
                    <CloseIcon onClick={toggle} />
                    <h1>Nri Tax</h1>
                </NavLeft>
                {
                    localStorage.getItem('userType') === "expert" ? (
                        null
                    ) : (
                        <SidebarSearchRoute to="/experts" onClick={toggle}>
                            <SearchIcon />
                        </SidebarSearchRoute>
                    )
                }
            </NavCont>

            <SidebarMenu>
                <SidebarLink>
                    <SidebarSearchRoute to="/" onClick={toggle}>
                        Home
                    </SidebarSearchRoute>
                </SidebarLink>
                <SidebarLink>
                    <SidebarSearchRoute to="/about-us" onClick={toggle}>
                        About us
                    </SidebarSearchRoute>
                </SidebarLink>
                <SidebarLink>
                    <SidebarSearchRoute to="/services" onClick={toggle}>
                        Services
                    </SidebarSearchRoute>
                </SidebarLink>
                <SidebarLink>
                    <SidebarSearchRoute to="/file-tips" onClick={toggle}>
                        File Tips
                    </SidebarSearchRoute>
                </SidebarLink>
                <SidebarLink>
                    <SidebarSearchRoute to="/contact" onClick={toggle}>
                        Contact
                    </SidebarSearchRoute>
                </SidebarLink>

                {user ? (
                    localStorage.getItem('userType') === "user" ? (
                        <>
                            <SidebarLink>
                                <SidebarSearchRoute to="/user-profile" onClick={toggle}>
                                    My Account
                                </SidebarSearchRoute>
                            </SidebarLink>
                        </>
                    ) : localStorage.getItem('userType') === "expert" ? (
                        <>
                            <SidebarLink>
                                <SidebarSearchRoute to="/expert-profile" onClick={toggle}>
                                    Profile
                                </SidebarSearchRoute>
                            </SidebarLink>
                            <SidebarLink>
                                <SidebarSearchRoute to="/allocated-users" onClick={toggle}>
                                    Customers
                                </SidebarSearchRoute>
                            </SidebarLink>
                        </>
                    ) : (
                        <>
                            <SidebarLink>
                                <SidebarSearchRoute to="/signin" onClick={toggle}>
                                    Sign In
                                </SidebarSearchRoute>
                            </SidebarLink>
                        </>
                    )
                ) : (
                    <>
                        <SidebarLink>
                            <SidebarSearchRoute to="/signin" onClick={toggle}>
                                Sign In
                            </SidebarSearchRoute>
                        </SidebarLink>
                    </>
                )}
            </SidebarMenu>

            <FootCont>
                {
                    user ? (
                        <SidebarRoute onClick={() => { toggle(); handleSignOut() }}>
                            Log Out
                        </SidebarRoute>
                    ) : (
                        <SidebarRoute to="/select-role" onClick={toggle}>
                            Sign Up
                        </SidebarRoute>
                    )
                }
            </FootCont>
        </SidebarContainer>
    )
}
