import React, { useState, useEffect } from 'react';
import styles from './Customers.module.css';
import { PulseLoader } from 'react-spinners';
import CustomerList from '../CustomerList/CustomerList';
import { Navbar } from '../navbar';
import { Sidebar } from '../sidebar';
import {Helmet} from "react-helmet";

const Customers = () => {
    const [allocatedUsers, setAllocatedUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true); 
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    // const expertId = "650b42ff10b9ef184e761ead";
    const expertId = localStorage.getItem('expertId');;
    useEffect(() => {
        fetchAllocatedUsers();
    },[]);

    // Fetch the allocated users for the expert based on expertId
    const fetchAllocatedUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/tax-expert/allocated-users/${expertId}`);
        const data = await response.json();
        if(data.success && data.allocatedUsers.length === 0) {
          setIsLoading(false);
        }
        setAllocatedUsers(data.allocatedUsers);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching allocated users:', error);
      }
    };

  return (
    <div className={styles['outer']}>
      <Helmet>
        <title>Manage Your Clients</title>
        <meta 
          name="description" 
          content="Effortlessly oversee and assist your assigned clients. Access client details, address queries, and offer expert NRI tax guidance with ease." 
        />
        <meta 
          name="keywords" 
          content="manage, clients, assist, queries" 
        />
      </Helmet>
      
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} backgroundColor="#192440"/>
      <div className={styles['customer']}>
        <h1>Allocated Users</h1>

        {isLoading ? (
          <PulseLoader className={styles['loader']} color={'#36D7B7'} size={15}  />
        ) : allocatedUsers.length === 0 ? (
          <p className={styles['no-users']}>No user allocated</p>
        ) : (
          <CustomerList customers={allocatedUsers} />
        )}
      </div>
    </div>
  );
};

export default Customers;
