import styled from "styled-components";

export const ServicesContainer = styled.div`
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        to top left,
        #00FFAD 95%,
        transparent 80%
    );
    padding: 50px 0;

    @media screen and (max-width: 768px) {
        min-height: 1100px;
        background-color: #00FFAD;
    }

    @media screen and (max-width: 480px) {
        min-height: 1300px;
    }

`

export const ServicesWrapper = styled.div`
    max-width: 1000px;
    ${'' /* margin: 0 auto; */}
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    ${'' /* align-items: center; */}
    grid-gap: 16px;
    padding: 20px 30px;

    @media screen and (max-width: 1050px) {
        grid-template-columns: 1fr 1fr;
        padding: 20px 30px;
    }

    @media screen and (max-width: 725px) {
        grid-template-columns: 1fr;
        padding: 20px 30px;
    }

`

export const ServicesCard = styled.div`
    background: white;
    display: flex;
    width: 300px;
    height: 500px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    max-height: 400px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);

    &:hover {
        transform: scale(1.02);
        background-color: #192440;
        color: white;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
    }

    @media screen and (max-width: 400px) {
        width: 80vw;
        height: 500px;
    }

`

export const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 40px;
    
`

export const ServicesH1 = styled.h1`
    color: black;
    font-size: 38px;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const ServicesH2 = styled.h2`
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: 900;
    
`

export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: center;

`
