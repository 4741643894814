import React, { useState, useRef, useEffect } from "react";
import { HeroContainer, HeroBg, HeroContent, HeroBtnWrapper, ArrowForward, ArrowRight } from "./HeroElements";
import { useAuthState } from "../../UserAuth";
import { ButtonR } from "../ButtonElements";
import "../../App.css";
import Typewriter from "typewriter-effect";
import { motion, useInView, useAnimation } from "framer-motion"
import GoogleSearch from "../GoogleSearch/GoogleSearch";

const HeroSection = () => {
  const user = useAuthState();
  const [hover, setHover] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView])

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer>
      <HeroBg />
      <HeroContent>
        <GoogleSearch />
        <div style={{marginTop: '5%'}}>
          <h1 className="h1-hero">
            {
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Filling Your Taxes Made Easy")
                    .start();
                }}
              />
            }
          </h1>
          <motion.div ref={ref}
            variants={{ hidden: { opacity: 0, x: -100 }, visible: { opacity: 1, x: 0 } }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.25 }}
          >
            <p className="hero-text">
              Navigating Taxes Across Borders: Your Guide to NRI Financial Freedom
            </p>
          </motion.div>
        </div>
        {
          user ?
            null :
            <>
              <HeroBtnWrapper>
                <ButtonR to="/select-role" onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true">
                  Get started {hover ? <ArrowForward /> : <ArrowRight />}
                </ButtonR>
              </HeroBtnWrapper>
            </>
        }
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
