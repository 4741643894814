import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#192440" : "transparent")};
  height: 80px;
  margin-top: -80px;
  width: 100%
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }

`;
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 100%;
`;
export const NavLogo = styled(LinkR)`
  color: white;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
  transition: all ease 0.2s;
  ${'' /* border: 2px solid pink; */}
`;

export const SearchButton = styled.button`
  border-radius: 100%;
  text-decoration: none;
  background: #00FFAD;
  white-space: nowrap;
  padding: 10px 15px;
  color: #010606;
  font-size: 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 900;
  ${'' /* border: 2px solid red; */}

  &:hover {
    transition: all 0.2s ease-in-out;
    color: white;
  }

  @media screen and (max-width: 1000px) {
    padding: 0.4rem 0.6rem;
    font-size: 0.8rem;
    justify-content: center;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  ${'' /* border: 2px solid red; */}

  @media screen and (max-width: 863px) {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  height: 80px;
  ${'' /* border: 2px solid red; */}

  @media screen and (max-width: 863px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  background-color: transparent;
  border: none;
  ${'' /* border: 2px solid green */}
`;

export const NavLinks = styled(LinkS)`
  color: grey;
  background: transparent;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.active {
    border-bottom: 3px solid #b05ffd;
  }

  &:hover {
    color: #00FFAD;
  }

  @media screen and (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;

export const NavLink = styled(LinkR)`
  color: grey;
  background: transparent;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.active {
    border-bottom: 3px solid #b05ffd;
  }

  &:hover {
    color: #00FFAD;
  }

  @media screen and (max-width: 1000px) {
    font-size: 0.8rem;
  }
`;

export const NavButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 863px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-left: 15px;
  @media screen and (max-width: 863px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  text-decoration: none;
  background: #00FFAD;
  white-space: nowrap;
  padding: 10px 22px;
  display: flex;
  color: #010606;
  font-size: 0.9rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 900;

  &:hover {
    transition: all 0.2s ease-in-out;
    color: white;
  }

  @media screen and (max-width: 1000px) {
    width: 5rem;
    padding: 0.4rem 0.4rem;
    font-size: 0.8rem;
    justify-content: center;
  }

  @media screen and (max-width: 863px) {
    display: none;
  }
`;
