import React from 'react';
import styles from './personalDetails.module.css';
import InputControl from '../InputControl/InputControl';
import { useExpertDetails } from '../../ExpertProfileContext';

const PersonalDetails = () => {
    const { personalDetails, setPersonalDetails } = useExpertDetails();
  return (
    <div className={styles.userDetails}>
        <div className={styles.headingContainer}>
          <h6>Personal Details</h6>
        </div>
        <div className={styles.detail}>
          <div className={styles.row}>
            <InputControl
              label="First Name"
              type="text"
              imp="true"
              placeholder="Enter the first name"
              value={personalDetails.firstName}
              onChange={(e) =>
                setPersonalDetails({ ...personalDetails, firstName: e.target.value })
              }
            />
            <InputControl
              label="Last Name"
              type="text"
              imp="true"
              placeholder="Enter the last name"
              value={personalDetails.lastName}
              onChange={(e) =>
                setPersonalDetails({ ...personalDetails, lastName: e.target.value })
              }
            />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Email"
            type="email"
            imp="true"
            placeholder="Enter email"
            value={personalDetails.email}
            onChange={(e) =>
              setPersonalDetails({ ...personalDetails, email: e.target.value })
            }
          />
          <InputControl
            label="Phone Number"
            type="number"
            imp="true"
            placeholder="Enter phone number"
            value={personalDetails.phoneNumber}
            onChange={(e) =>
              setPersonalDetails({ ...personalDetails, phoneNumber: e.target.value })
            }
          />
          <InputControl
            label="DOB"
            type="text"
            placeholder="Enter DOB (04/06/23)"
            value={personalDetails.dateOfBirth}
            onChange={(e) =>
              setPersonalDetails({ ...personalDetails, dateOfBirth: e.target.value })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Address"
            type="text"
            placeholder="Enter address"
            value={personalDetails.address}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                address: e.target.value,
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="City"
            type="text"
            imp="true"
            placeholder="Enter city"
            value={personalDetails.city}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                city: e.target.value,
              })
            }
          />
          <InputControl
            label="State"
            type="text"
            imp="true"
            placeholder="Enter state"
            value={personalDetails.state}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                state: e.target.value,
              })
            }
          />

            <InputControl
            label="ZipCode"
            type="number"
            placeholder="Enter zipcode"
            value={personalDetails.zipCode}
            onChange={(e) =>
                setPersonalDetails({
                ...personalDetails,
                zipCode: e.target.value,
                })
            }
            />
        </div>
    </div>
    </div>
  )
}

export default PersonalDetails
