import React, { useState, useEffect } from 'react'
import styles from './ExpertProfile.module.css';
import { Sidebar } from '../../components/sidebar';
import { Navbar } from '../../components/navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PulseLoader } from 'react-spinners';
import axios from 'axios';
import { useExpertDetails } from '../../ExpertProfileContext';
import EducationDetails from '../../components/EducationDetails/EducationDetails';
import ExperienceDetails from '../../components/ExperienceDetails/ExperienceDetails';
import PersonalDetails from '../../components/personalDetails/personalDetails';

const ExpertProfile = () => {
  const expertId = localStorage.getItem('expertId');
  const { personalDetails, setPersonalDetails, educationDetails, setEducationDetails, experienceDetails, setExperienceDetails } = useExpertDetails();

  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const handleSaveClick = async (e) => {
    e.preventDefault();
    if ( !personalDetails.firstName || !personalDetails.lastName || !personalDetails.email || !personalDetails.phoneNumber ||
      !personalDetails.city || !personalDetails.state) {
      toast.warning("Please fill in all required fields");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/tax-expert/save-profile`, {
        expertId: expertId,
        personalDetails: personalDetails,
        educationDetails: educationDetails,
        experienceDetails: experienceDetails
      }
      );

      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }

    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  useEffect(() => {
    const fetchExpertDetails = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/tax-expert/fetch-profile`, { expertId: expertId });

        const { firstName, lastName, email, phoneNumber, dateOfBirth, address, city, state, zipCode } = response.data.data.personalDetails;

        setPersonalDetails({ firstName: firstName, lastName: lastName, email: email, phoneNumber: phoneNumber, dateOfBirth: dateOfBirth, address: address, city: city, state: state, zipCode: zipCode });

        if (response.data.data.educationDetails !== null) {
          const { degree, university, fieldOfStudy, graduationYear } = response.data.data.educationDetails;
          setEducationDetails({ degree: degree, university: university, fieldOfStudy: fieldOfStudy, graduationYear: graduationYear });
        }

        if (response.data.data.experienceDetails !== null) {
          const { description, linkedin } = response.data.data.experienceDetails;
          setExperienceDetails({ description: description, linkedin: linkedin });
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchExpertDetails();
  }, []);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} backgroundColor="#192440" />
      <div className={styles.container}>
        {loading
          ? <PulseLoader className={styles.loader} color={'#36D7B7'} size={13} />
          : <div className={styles.profileSection}>
              <PersonalDetails/>
              <EducationDetails/>
              <ExperienceDetails/>
            <div className={styles.submitButton}>
              <button onClick={handleSaveClick} type='submit'>Save</button>
            </div>
          </div>}
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick
          rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      </div>
    </>
  )
}

export default ExpertProfile
