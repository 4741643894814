const API_KEY = process.env.REACT_APP_GOOGLE_SEARCH_API_KEY;
const ENGINE_ID = process.env.REACT_APP_GOOGLE_SEARCH_ENGINE_ID;

const searchData = async (searchTerm) => {
    let data;
    try {
        data = await fetch(`https://www.googleapis.com/customsearch/v1?key=${API_KEY}&cx=${ENGINE_ID}&q=${searchTerm}`);
    } catch (error) {
        console.log(error);
    }
    const result = data.json();

    return result;
};

export default searchData;
