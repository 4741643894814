import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FacebookAuthProvider, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import InputControl from "../../../components/inputController";
import { auth } from "../../../firebase";
import axios from 'axios';
import "./style.css";
import { useUser } from "../../../UserContext";
import { PulseLoader } from 'react-spinners';
import { Helmet } from "react-helmet";

const Login = () => {
  const { userType, setUserType } = useUser();
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");

  const navigate = useNavigate();
  const [value, setValue] = useState({ email: "", password: "" });

  // function to check the user's role (expert or user) based on email
  const checkUserRole = async (email) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/tax-expert/check-email-expert?email=${email}`);
      return response.data.role;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handlesubmission = async () => {
    if (value.email === '' || value.password === '') {
      setErrormsg("Fill all the fields");
      setTimeout(() => setErrormsg(""), 2000);
      return;
    }
    setLoading(true);
    try {
      const res = await signInWithEmailAndPassword(auth, value.email, value.password);
      const role = await checkUserRole(value.email);

      if (role === 'expert') {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/tax-expert/sign-in`, value);
        const expertId = response.data.data._id
        localStorage.setItem('expertId', expertId);
        setUserType(role);
        // After successfully setting userType, store it in local storage
        localStorage.setItem('userType', role);
        navigate(`/`);
      } else {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/sign-in`, value);
        const userId = response.data.data._id
        localStorage.setItem('userId', userId);
        setUserType(role);
        // After successfully setting userType, store it in local storage
        localStorage.setItem('userType', role);
        navigate(`/`);
      }

      setValue({ email: "", password: "" });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrormsg(error.message);
      if (error.code === "auth/user-not-found") {
        setErrormsg("User not found. Please sign up.");
      }
      setTimeout(() => setErrormsg(""), 2000);
    }
  };

  // login through google
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const email = user.email;
      const role = await checkUserRole(email);

      if (role === 'expert') {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/tax-expert/sign-in`, { "email": email, "password": "temporary" });
        const expertId = response.data.data._id
        localStorage.setItem('expertId', expertId);
        setUserType(role);
        // After successfully setting userType, store it in local storage
        localStorage.setItem('userType', role);
        navigate(`/`);
      } else if (role === 'user') {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/sign-in`, { "email": email, "password": "temporary" });
        const userId = response.data.data._id
        localStorage.setItem('userId', userId);
        setUserType(role);
        // After successfully setting userType, store it in local storage
        localStorage.setItem('userType', role);
        navigate(`/`);
      } else {
        // user does not exist ao delete it from firebase
        await user.delete();
        setUserType(null);
        // After successfully setting userType, store it in local storage
        localStorage.setItem('userType', null);
        setLoading(false);
        setErrormsg("User not found. Please sign up");
        setTimeout(() => setErrormsg(""), 2000);
      }
    } catch (error) {
      setLoading(false);
      if (error.code === "auth/popup-closed-by-user") {
        setErrormsg("Google sign-in popup was closed by the user.");
      } else {
        console.error(error);
      }
      setTimeout(() => setErrormsg(""), 2000);
    }
  };

  const signWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const email = result.user.email;
      const role = await checkUserRole(email);

      if (role === 'expert') {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/tax-expert/sign-in`, { "email": email, "password": "temporary" });
        const expertId = response.data.data._id
        localStorage.setItem('expertId', expertId);
        setUserType(role);
        // After successfully setting userType, store it in local storage
        localStorage.setItem('userType', role);
        navigate(`/`);
      } else if (role === 'user') {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/sign-in`, { "email": email, "password": "temporary" });
        const userId = response.data.data._id
        // console.log(userId);
        localStorage.setItem('userId', userId);

        setUserType(role);
        // After successfully setting userType, store it in local storage
        localStorage.setItem('userType', role);
      } else {
        // Deny access and sign out the user
        await auth.signOut();
        setUserType(null);
        // After successfully setting userType, store it in local storage
        localStorage.setItem('userType', null);
        setErrormsg("User not found. Please sign up");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.code === "auth/popup-closed-by-user") {
        setErrormsg("Facebook sign-in popup was closed by the user.");
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div className="login_container ">
      <Helmet>
        <title>NRI Tax - Log In</title>
        <meta
          name="description"
          content="Access your NRI tax account to manage your queries and consultations effortlessly. Get expert guidance on all your tax-related matters."
        />
        <meta
          name="keywords"
          content="Log in, Sign in, User login, Expert login, Account access, Secure login"
        />
      </Helmet>
      <div className="login_innerBox">
        <div className="back">
          <button
            className="btn back_btn"
            onClick={() => {
              navigate("/");
            }}
          >
            <i className="fa fa-angle-double-left"></i> Back
          </button>
        </div>
        <h1 className="login_heading">Sign In to your Account</h1>
        <InputControl
          type="email"
          label="Email:"
          placeholder="Enter email"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, email: e.target.value }))
          }
          value={value.email}
        />
        <InputControl
          type="password"
          label="Password:"
          placeholder="Enter Password"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, password: e.target.value }))
          }
          value={value.password}
        />
        <p>
          <Link to="/forgot_password">Forgot Password?</Link>
        </p>

        <div className="login_method">
          <button
            className="btn btn-block btn-social btn-facebook"
            onClick={signWithFacebook}
          >
            <i className="fab fa-facebook"></i>{" "}
            <span className="icon">Sign In with Facebook</span>
          </button>

          <button
            className="btn btn-block btn-social btn-google"
            onClick={signInWithGoogle}
          >
            <i className="fab fa-google"></i>{" "}
            <span className="icon">Sign In with Google</span>
          </button>
        </div>

        <div className="login_footer">
          <b className="login_error">{errormsg}</b>
          <button onClick={handlesubmission}>
            {loading ? <PulseLoader color={'#36D7B7'} size={13} /> : 'Log In'}
          </button>
          <p className="login_p">
            Create an account?{" "}
            <span>
              <Link to="/select-role">sign up</Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
