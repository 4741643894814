import React, { useState, useEffect } from 'react';
import { Sidebar } from '../../components/sidebar';
import { Navbar } from '../../components/navbar';
import styles from './UserProfile.module.css';
import { PulseLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const UserProfile = () => {
    const userId = localStorage.getItem('userId');
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const [userDetails, setUserDetails] = useState({
        userName: "",
        email: "",
        phoneNo: "",
        state: "",
        city: "",
        profilePic: ""
    })

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/fetch-profile`, {
                    userId: userId,
                });
                const { name, email, phone, state, city, profilePic } = response.data.data;
                setUserDetails({ userName: name, email: email, phoneNo: phone, state: state, city: city, profilePic: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJIyiiNbz3VC9Afr-LqWpcw7loxjPnacfZSA&usqp=CAU"});
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        fetchUserDetails();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setUserDetails((prevDetails) => ({
                ...prevDetails,
                profilePic: imageUrl,
            }));
        }
    };

    const saveChanges = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/save-profile`, {
                userId: userId,
                userDetails
            })
            if (res.data.success) {
                toast.success('Details saved successfully');
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
        {userDetails.profilePic}
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} backgroundColor="#192440" />
            <div className={styles.container}>
                <div className={styles.profileSection}>
                    <h4>EDIT PROFILE</h4>
                    {
                        loading
                            ? <PulseLoader className={styles.loader} color={'#36D7B7'} size={13} />
                            : <div>
                                <div className={styles.profilePicContainer}>
                                    <label htmlFor="profilePicInput">
                                        <img src={userDetails.profilePic} alt="profile pic" />
                                        {/* <img src="" alt="profile pic" /> */}
                                    </label>
                                    {/* <input type="file" onChange={handleImageChange} id="profilePicInput" /> */}
                                </div>
                                <div className={styles.userWrapper}>
                                    <form id="userProfileForm" className={styles.userDetails} onSubmit={saveChanges}>
                                        <label>
                                            <span>Username:</span>
                                            <input placeholder='John Doe' type="text" name="userName" value={userDetails.userName} onChange={handleInputChange} required />
                                        </label>
                                        <label>
                                            <span>Email:</span>
                                            <input placeholder='johndoe@gmail.com' type="email" name="email" value={userDetails.email} onChange={handleInputChange} required />
                                        </label>
                                        <label className={styles.colouredBackground}>
                                            <span>Phone No:</span>
                                            <input placeholder='123456789' type="number" name="phoneNo" value={userDetails.phoneNo} onChange={handleInputChange} required />
                                        </label>
                                        <label>
                                            <span>State:</span>
                                            <input placeholder='California' type="text" name="state" value={userDetails.state} onChange={handleInputChange} required />
                                        </label>
                                        <label className={styles.colouredBackground}>
                                            <span>City:</span>
                                            <input placeholder='San Francisco' type="text" name="city" value={userDetails.city} onChange={handleInputChange} required />
                                        </label>
                                    </form>
                                </div>
                                <div className={styles.saveButton}>
                                    <button form="userProfileForm" type="submit">Save</button>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    );
};

export default UserProfile;


