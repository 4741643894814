import React, { useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../../../firebase";
import InputControl from "../../../components/inputController";
import axios from 'axios';
import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useUser } from "../../../UserContext";
import { PulseLoader } from 'react-spinners';
import { Helmet } from "react-helmet";

const Signup = () => {
  const { userType, setUserType } = useUser();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({ name: "", email: "", password: "" });
  const [errormsg, setErrormsg] = useState("");
  const navigate = useNavigate();

  const handlesubmission = async () => {
    if (!value.name || !value.email || !value.password) {
      setErrormsg("Fill all the fields");
      setTimeout(() => setErrormsg(""), 2000);
      return;
    }
    // loading start
    setLoading(true);
    // After successfully setting userType, store it in local storage
    localStorage.setItem('userType', userType);

    createUserWithEmailAndPassword(auth, value.email, value.password)
      .then(async (res) => {
        if (userType === 'user') {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/sign-up`, value);
          const userId = response.data.data._id
          localStorage.setItem('userId', userId);
        } else {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/tax-expert/sign-up`, value);
          const expertId = response.data.data._id
          localStorage.setItem('expertId', expertId);
        }
        // make the fields empty
        setValue({ name: "", email: "", password: "", });
        navigate(`/`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrormsg(error.message);
        setTimeout(() => setErrormsg(""), 2000);
      });
  };

  const handleSocialSignUp = async (user, userType) => {
    const name = user.displayName;
    const email = user.email;
    const password = null;
    try {
      const isExpertExist = await axios.post(`${process.env.REACT_APP_BASE_URL}/tax-expert/check-expert-exist`, { email });
      const isUserExist = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/check-user-exist`, { email });

      if (!isExpertExist.data.success) {
        await auth.signOut();
        setErrormsg(isExpertExist.data.message);
        setTimeout(() => setErrormsg(""), 2000);
        return;
      }

      if (!isUserExist.data.success) {
        await auth.signOut();
        setErrormsg(isUserExist.data.message);
        setTimeout(() => setErrormsg(""), 2000);
        return;
      }

      if (userType === 'user') {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/sign-up`, { name, email, password });
        const userId = response.data.data._id;
        localStorage.setItem('userId', userId);
      } else {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/tax-expert/sign-up`, { name, email, password });
        const expertId = response.data.data._id
        localStorage.setItem('expertId', expertId);
      }

      // After successfully setting userType, store it in local storage
      localStorage.setItem('userType', userType);
      navigate(`/`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // signup through google
  const signUpWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await handleSocialSignUp(user, userType);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // signup through facebook
  const signUpWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    setLoading(true);
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user; // User object containing user information
        await handleSocialSignUp(user, userType);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === "auth/account-exists-with-different-credential") {
          setErrormsg(
            "An account with this email already exists. Sign in using the same provider you used during sign-up."
          );
        } else {
          console.log(error);
        }
      });
  };

  return (
    <div className="signup_container">
      <Helmet>
        <title>Join NRI Tax - Create Your Account</title>
        <meta
          name="description"
          content="Start your NRI tax journey today. Sign up for expert assistance, personalized advice, and simplified tax solutions tailored to your needs."
        />
        <meta
          name="keywords"
          content="Register, Join, Create account, Sign up, NRI tax services, User registration, Expert registration, Account setup"
        />
      </Helmet>

      <div className="signup_innerBox">
        <div className="back">
          <button
            className="btn back_btn"
            onClick={() => {
              navigate("/");
            }}
          >
            <i className="fa fa-angle-double-left"></i> Back
          </button>
        </div>
        <h1 className="signup_heading">Sign Up</h1>
        <InputControl
          type="text"
          label="Name:"
          placeholder="Enter your Name"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, name: e.target.value }))
          }
          value={value.name}
        />
        <InputControl
          type="email"
          label="Email:"
          placeholder="Enter email"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, email: e.target.value }))
          }
          value={value.email}
        />
        <InputControl
          type="password"
          label="Password:"
          placeholder="Enter Password"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, password: e.target.value }))
          }
          value={value.password}
        />

        <div className="login_method">
          <button
            className="btn btn-block btn-social btn-facebook"
            onClick={signUpWithFacebook}
          >
            <i className="fab fa-facebook"></i>{" "}
            <span className="icon">Sign Up with Facebook</span>
          </button>

          <button
            className="btn btn-block btn-social btn-google"
            onClick={signUpWithGoogle}
          >
            <i className="fab fa-google"></i>{" "}
            <span className="icon">Sign Up with Google</span>
          </button>
        </div>

        <div className="signup_footer">
          <b className="signup_error">{errormsg}</b>
          <button onClick={handlesubmission}>
            {loading ? <PulseLoader color={'#36D7B7'} size={13} /> : 'SignUp'}
          </button>
          <p className="signup_p">
            Already have an acccount?{" "}
            <span>
              <Link to="/signin">Login</Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
